import React, { useContext, useState, useEffect } from "react";
import Partner from "../components/Partner/Partner";
import "./style.css";
import { aboutPartners } from "../helpers/aboutPartners";
import { AudioContext } from "./../utils/SongContext";
import { NavLink } from "react-router-dom";

const AboutProject = () => {
    const { setSongIndex, isPlaying, setIsPlaying } = useContext(AudioContext);
    const [activeIndex, setActiveIndex] = useState(0);
    


    useEffect(() => {
        setSongIndex(0);
    }, [setSongIndex]);

    const handleArchiveClick = (index) => {
        const playBtn = document.querySelector('.polygon1, .polygon3');
        console.log("push button checkbox");
        if (index !== activeIndex) {
            setSongIndex(index);
            setActiveIndex(index);
            console.log("set another index");
            if (isPlaying) {
                setIsPlaying(false);
                playBtn.classList.remove('polygon3');
                playBtn.classList.add('polygon1');
            }
        }
        
    };

    const handlePlayClick = () => {
        const playBtn = document.querySelector('.polygon1, .polygon3');
        if (playBtn) {
            playBtn.classList.toggle('polygon1');
            playBtn.classList.toggle('polygon3');
        }
        if (!isPlaying) {
            setIsPlaying(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });

        }
    };

    return (
        <div className="aboutproject">
            <div className="about_project">
                <ul>
                    <li>О проекте</li>
                    <li><i>«Без лечения ран прошлого нельзя строить будущее»</i></li>
                    <li>Проект «ИМЕНА» это ежегодное чтение имен деятелей культуры пострадавших в годы советских репрессий каждое 30 октября*. </li>
                    <li>Наша цель: Сохранять память о  деятелях культуры подвергшихся советским репрессиям.<br></br> А задачи, которыми мы занимаемся: сбор и и архивирование информации о репрессированных; проведение ежегодных чтений. </li>
                    <li>Через чтения имен мы пытаемся воскресить этих людей, не дать им умереть и потеряться окончательно в памяти. Мы не хотим, чтобы эти люди ушли в небытие, если мы помним этих людей, значит они живы. </li>
                    <li>Важно помнить о травме, которая была нанесена русскому народу через репрессии против этих людей. Не все пострадавшие являются русскими по крови, но они являются русскими по духу. </li>
                    <li>Вы можете познакомиться с нашим проектом через небольшой архив аудио записей чтений, собранный за несколько лет. Архив ниже.</li>
                    <li>Вы можете познакомиться с именами пострадавших деятелей культуры на главной <NavLink to="/" className="about_project__link"><b>странице</b></NavLink>.</li>
                    <li>Вы можете присоединиться к ежегодному чтению ИМЕН, отправив заявку по <a href="https://docs.google.com/forms/d/19er_GTQvLqcn7drZZvE0kySkGc8oB4Rk3BxEEjxdc2U/viewform?edit_requested=true&fbzx=7816709930990266282" target="blank_" className="about_project__link"><b>ссылке</b>.</a></li>
                    <li className="about_project__smalltext">*30 октября в России отмечается День памяти жертв политических репрессий.
                        Трагедия первой половины 20 века коснулась судеб очень и очень многих людей, попавших под массовые аресты, выселения, расстрелы.

                        30 октября 1974 года узники мордовских и пермских лагерей в знак протеста против политических репрессий в СССР начали голодовку. Они объявили этот день Днем политзаключенных в СССР. С тех пор каждое 30 октября в Советском Союзе ежегодно проходили голодовки политзаключенных. И только в 1991 году, Верховный Совет РСФСР закрепил за этой датой «День памяти жертв политических репрессий".</li>
                    <li><b>У вас есть информация о деятелях культуры, пострадавшем в годы советских репрессий? Мы будем благодарны, если вы поделитесь с нами, этой информацией.</b> </li>
                    <li>
                        <div className="about_project-btnwrp">
                            <a href="https://docs.google.com/forms/d/19er_GTQvLqcn7drZZvE0kySkGc8oB4Rk3BxEEjxdc2U/viewform?edit_requested=true&fbzx=7816709930990266282" target="blank_">
                                <div className="about_project-btn">связаться</div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="archive">
                <ul className="archive__st">
                    <li>Записи чтений</li>
                    <li>Здесь можно выбрать архивную запись чтений в рамках проекта “ИМЕНА”.</li>
                </ul>
                <div className="nd__wrapper">
                    <div className="nd__wrapper-wrap">
                        <ul className="archive__nd">
                            <li className="archive__pl">
                                Чтения имен 2021
                                <div
                                    className={activeIndex === 0 ? "pl-checkbox checked" : "pl-checkbox"}
                                    onClick={() => handleArchiveClick(0)}
                                ></div>
                            </li>
                            <li className="archive__pl">
                                Чтения имен 2022
                                <div
                                    className={activeIndex === 1 ? "pl-checkbox checked" : "pl-checkbox"}
                                    onClick={() => handleArchiveClick(1)}
                                ></div>
                            </li>
                            <li className="archive__pl">
                                Чтения имен 2023
                                <div
                                    className={activeIndex === 2 ? "pl-checkbox checked" : "pl-checkbox"}
                                    onClick={() => handleArchiveClick(2)}
                                ></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="archive-btnwrp">
                    <div className="archive_project-btn" onClick={handlePlayClick}>слушать</div>
                </div>
            </div>
            <div className="source">
                <div className="source__title">Партнеры и источники</div>
                {aboutPartners.map((partner) => {
                    return (
                        <Partner
                            title={partner.title}
                            info={partner.info}
                            link={partner.link}
                            key={partner.id}
                            id={partner.id}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default AboutProject;