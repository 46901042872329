import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css';
import './style.css';
import slide1 from "./../../media/img/slider/image3.png";
import slide2 from "./../../media/img/slider/image2.jpg"
import slideMobile from "./../../media/img/slider/image3.png";

const SwiperMain = () => {
    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar]}
                spaceBetween={50}
                slidesPerView={1}
                lazyPreloadPrevNext={1}
                navigation={{
                    nextEl: '.slider__nav-next',
                    prevEl: '.slider__nav-prev',
                }}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'fraction',
                }}

                initialSlide={0}
                speed={800}
                allowTouchMove={false}
            >
                <SwiperSlide>
                    <div className="slider__title">Приглашаем на чтения имен 2024</div>
                    <img src={slide1} alt="" loading='lazy' className="desktop-img" />
                    <img src={slideMobile} alt="" loading='lazy' className="mobile-img" />
                    <div className="slider__text">
                        <p>Друзья, мы ежегодно читаем и вспоминаем имена деятелей культуры,  пострадавших в годы советских репрессий.</p><br></br>
                        <p>Чтение Имён состоится онлайн  29 октября.</p><br></br>
                        <p>Приглашаем вас к участию!</p>
                        Для этого просим вас заполнить анкету по ссылке  ниже.
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider__title">Приглашаем на чтения имен 2025</div>
                    <img src={slide2} alt="" loading='lazy' className="desktop-img" />
                    <img src={slideMobile} alt="" loading='lazy' className="mobile-img" />
                    <div className="slider__text">
                        coming soon...
                    </div>
                </SwiperSlide>
                <div className="swiper-scrollbar"></div>

            </Swiper>

        </>
    );
}

export default SwiperMain;