const aboutNames = [
    {
        "name": "Овчинников Владимир Александрович",
        "date": "1938",
        "info": "Художник, краевед, общественный деятель, создатель настенной живописи в городе Боровске, Закончил МИСИ, работал на стройках, занимался научной работой, кандидат экономических наук. Его отец был репрессирован и провёл в заключении 10 лет. "
    },
    {
        "name": "Абламский Иван Дмитриевич ",
        "date": "1886",
        "info": "Беспартийный; художник-оформитель музея. Приговорен к высшей мере наказания — расстрел. Реабилитирован 28 апреля 1956 года"
    },
    {
        "name": "Акулов Гавриил Иванович ",
        "info": "Архитектор, художник."
    },
    {
        "name": "Дании́л Ива́нович Хармс",
        "info": "Русский и советский писатель, поэт и драматург. Основатель объединения деятелей культуры-новаторов «ОБЭРИУ». Скончался в тюремной больнице 2 февраля 1942 года в Ленинграде. Был репрессирован, арестован 23 августа 1941 года.  Реабилитирован 25 июля 1960 года."
    },
    {
        "name": "Акцынов Аркадий Всеволодович ",
        "date": "1910-1997",
        "info": "Художник. 1938. — Арест. Допросы. Пытки. Перевод в Бутырскую тюрьму. Отказ признать себя виновным. Отправка А.В. Акцынова этапом по железной дороге на Дальний Восток. 1950, 1 января. — Второй арест. Следствие. Приговор: ссылка в Сибирь."
    },
    {
        "name": "Акцынова Людмила Михайловна ",
        "info": "Художник."
    },
    {
        "name": "Алексеев Иван Викторович ",
        "date": "1894-1964",
        "info": "Театральный художник."
    },
    {
        "name": "Алфераки Николай Ахиллесович ",
        "date": "1883"
    },
    {
        "name": "Ананьин РуфинСазонтович",
        "info": "График-карикатурист, театральный художник."
    },
    {
        "name": "Андерсон Вольдемар Петрович",
        "date": "1891",
        "info": "Живописец."
    },
    {
        "name": "Андреев Даниил Леонидович ",
        "date": "1906"
    },
    {
        "name": "Андреев Николай Андреевич",
        "date": "1889-1939"
    },
    {
        "name": "Андреева Алла Александровна",
        "date": "1915-2005",
        "info": "График. Репрессирована 23.04.1947 г. по делу мужа - писателя Д.Л.Андреева. Следствие шло на Лубянке и в Лефортовской тюрьме. Постановлением ОСО от 30.10.1948 г. приговорена к 25 годам ИТЛ с последующим поражением в правах на 5 лет.  Освобождена 13.08.1956 г."
    },
    {
        "name": "Андреевич-Михайлович Андрей Андреевич ",
        "date": "1896"
    },
    {
        "name": "Аникин Петр Иванович",
        "date": "1917"
    },
    {
        "name": "Аникин Сергей Алексеевич"
    },
    {
        "name": "Анисимов Александр Иванович",
        "info": "Практик-реставратор."
    },
    {
        "name": "Анисимов Сергей Николаевич",
        "date": "1907"
    },
    {
        "name": "Антощенко-Оленев Валентин Иосифович ",
        "info": "График. "
    },
    {
        "name": "Апушкин Яков Владимирович ",
        "info": "Литератор, художник."
    },
    {
        "name": "Артобатская Анна Ивановна ",
        "info": "Живописец",
        "Приговор": "8 лет исправительно-трудовых лагерей; освобождена 19 апреля 1946 года по окончании срока без права выезда. Реабилитация-1956"
    },
    {
        "name": "Артюхова Аделаида",
        "date": "1902-1936"
    },
    {
        "name": "Арцыбушев Алексей Петрович",
        "info": "График."
    },
    {
        "name": "Атанасиадис Антон Афанасьевич ",
        "info": "Художник книги, график."
    },
    {
        "name": " Ашмарин Вадим Степанович ",
        "date": "1915"
    },
    {
        "name": "Баженов Михаил Васильевич",
        "date": "1876",
        "info": "Беспартийный; преподаватель Костромской художественной школы. высшая мера наказания — расстрел. Реабилитация - 1964"
    },
    {
        "name": "Балашов Николай Иванович",
        "info": "Художник, священнослужитель"
    },
    {
        "name": "Балакирев Александр Петрович",
        "date": "1889",
        "info": "Беспартийный; художник Костромского товарищества «Художник»\nПриговорен  к высшей мере наказания — расстрел\nРеабилитирован в 1960 году"
    },
    {
        "name": "Банцан\nРоберт Фрицевич",
        "date": "1891-1938",
        "info": " драматург, художник, актёр, директор театра Скатувэ; Арестован 1.12.1937.Решением Комиссии Народного комиссариата внутренних дел (НКВД) и Прокуратуры СССР от 24.01.1938 приговорён к расстрелу.  Приговор приведён в исполнение 3.02.1938. Захоронен на Бутовском полигоне. \n"
    },
    {
        "name": "Бартенев Игорь Александрович"
    },
    {
        "name": "Баскин Юрий Соломонович",
        "info": "Художник-оформитель, монументалист"
    },
    {
        "name": "БатозскийТаврион Данилович",
        "date": "1898"
    },
    {
        "name": "Батурин Александр Борисович",
        "date": "1914-2003",
        "info": "График"
    },
    {
        "name": "Батюшков Василий Николаевич",
        "info": "художник-оформитель, прикладник"
    },
    {
        "name": "Баум Карл Придович",
        "info": "Художник"
    },
    {
        "name": "Бауэр (Бауер) Алексей Николаевич",
        "info": "Художник"
    },
    {
        "name": "Белокрылов Иван Васильевич",
        "date": "1922-1997"
    },
    {
        "name": "Бендель\nПетр Эмильевич",
        "date": "1905-1989",
        "info": "график, театральный художник;"
    },
    {
        "name": "Бендис Моисей Цолялович",
        "date": "1907"
    },
    {
        "name": "Берзин Альберт Петрович",
        "info": "художник"
    },
    {
        "name": "Беспахотный Дмитрий Петрович",
        "info": "Художник"
    },
    {
        "name": "Бесперстова Вера Петровна",
        "info": "Живописец"
    },
    {
        "name": "Бессмертнов Иван Спиридонович",
        "info": "Художник"
    },
    {
        "name": "Бигос Сергей Демьянович",
        "date": "1895г.р.",
        "info": "График, книжный иллюстратор. 5 лет исправительно-трудового лагеря. Реабилитация - 1989. скончался от туберкулёза легких в лагере. В начале 1929 за \"принадлежность к троцкистской организации\" приговорен к ссылке в Тамбов на два года;  после подачи заявления \"о прекращении фракционности\" освобожден, вернулся в Москву; в январе 1930 получил диплом ВХУТЕИНА...\n\n"
    },
    {
        "name": "Биргер Борис Григорьевич"
    },
    {
        "name": "Бирюлин Александр Павлович"
    },
    {
        "name": "Блощинский Б.В.",
        "date": "1921-1996"
    },
    {
        "name": "Блюм (Квятковский) Феликс Григорьевич",
        "info": "Скульптор-монументалист"
    },
    {
        "name": "Богатенко Яков Алексеевич",
        "info": "Иконописец, реставратор, композитор. Профессор с 1917"
    },
    {
        "name": "Богданчиков Михаил Васильевич",
        "info": "живописец"
    },
    {
        "name": "Богомолов Глеб",
        "info": "художник-авангардист"
    },
    {
        "name": "Богословский Дмитрий Фёдорович",
        "info": "Художник-реставратор"
    },
    {
        "name": "Боград Израиль Давыдович",
        "info": "Художник"
    },
    {
        "name": "Бойчук Михаил Львович",
        "date": "1882-1937"
    },
    {
        "name": "Бонч-Осмоловский Николай Георгиевич",
        "info": "Художник, монументалист"
    },
    {
        "name": "Борисов Сергей Иванович"
    },
    {
        "name": "Боровая Н.М.",
        "date": "1909-1987"
    },
    {
        "name": "Бороздин Иван Петрович",
        "info": "Художник-иконописец, протоиерей"
    },
    {
        "name": "Борхерт Эрих Вильгельмович",
        "date": "1907-1944",
        "info": "Художник"
    },
    {
        "name": "Борхман Ирина Александровна",
        "date": "1902-1995",
        "info": "График, прикладник"
    },
    {
        "name": "Боцех Андрей Иванович"
    },
    {
        "name": "Браз Осип (Иосиф) Эммануилович",
        "date": "1873-1936",
        "info": "Живописец. Академик с 1914"
    },
    {
        "name": "Браусов Александр Васильевич",
        "info": "Художник"
    },
    {
        "name": "Бруни Николай Александрович",
        "date": "1891-1938",
        "info": "Инженер, художник-скульптор в стройконторе управления п.Чибью. Приговорен  к высшей мере наказания — расстрел"
    },
    {
        "name": "Брунштейн Шлема Хайма Давидович",
        "date": "1915"
    },
    {
        "name": "Брюммер Леонид Владимирович",
        "date": "1889-1971",
        "info": "Живописец, пейзажист"
    },
    {
        "name": "Будаев Отсар Будаевич",
        "info": "Художник"
    },
    {
        "name": "Буинцев Пётр Никитич",
        "info": "Художник"
    },
    {
        "name": "Букович Иосиф Михайлович",
        "info": "Художник"
    },
    {
        "name": "Булычев Павел Васильевич",
        "info": "Театральный художник"
    },
    {
        "name": "Бурлаков Евгений Дмитриевич",
        "info": "Скульптор"
    },
    {
        "name": "Буш Виктор Петрович"
    },
    {
        "name": "Быстролетов Дмитрий Александрович",
        "info": "График"
    },
    {
        "name": "Вагнер Георгий Карлович"
    },
    {
        "name": "Вайсблат Иосиф Наумович",
        "date": "1893",
        "info": "Художник. 10 лет исправительно-трудового лагеря; освобожден досрочно 29 октября 1954 года"
    },
    {
        "name": "Валентинов (Миллер) Эрик Эдуардович",
        "info": "Театральный художник"
    },
    {
        "name": "Варзугин Валерий Михайлович",
        "info": "Мастер-руководитель фабрики \"Гознак\"; художник-полиграфист. 3 года исправительно-трудового лагеря"
    },
    {
        "name": "Валешкевич Елена Ивановна",
        "info": "Художник"
    },
    {
        "name": "Васильев Герман Александрович",
        "date": "1893"
    },
    {
        "name": "Васильев Николай Данилович"
    },
    {
        "name": "Васильев Юрий Борисович",
        "info": "Художник книги"
    },
    {
        "name": "Васильева Алла Федоровна ",
        "info": "Живописец"
    },
    {
        "name": "Вегенер Леонид Винфридович ",
        "date": "22 октября 1908 г.",
        "info": "Театральный художник, сценограф, живописец. 8 лет исправительно-трудовых лагерей"
    },
    {
        "name": "Вейдеман(ис) Карл Янович ",
        "date": "1897-1938",
        "info": "живописец, театральный художник; Постановлением Комиссии Народного комиссариата внутренних дел (НКВД) и Прокуратуры СССР от 11.02.1938 приговорен к расстрелу. Приговор приведен в исполнение 26.02.1938. Расстрелян и захоронен на Бутовском полигоне."
    },
    {
        "name": "Вермус Иуда Шаевич (ЮдаШайович) ",
        "info": "Художник-график"
    },
    {
        "name": "Весели Франц Иосифович \n",
        "date": "1891-1938",
        "info": "график, плакатист. Арестован 10 февраля 1938. Тройкой при УНКВД по Саратовской области от 27 августа 1938 г. по обвинению в шпионаже назначена высшая мера наказания - расстрел. Приговор приведен в исполнение 4 октября 1938 года в Саратове"
    },
    {
        "name": "Веселовский Евгений Степанович ",
        "info": "Художник"
    },
    {
        "name": "Вильде Рудольф Теодорович  ",
        "date": "1868 -1938",
        "info": "художник,живописец, график, педагог"
    },
    {
        "name": "Вингорский Андрей Кузьмич",
        "info": "График"
    },
    {
        "name": "Винивитин Евсей Потапович",
        "info": "График"
    },
    {
        "name": "Виноградов Василий Тимофеевич",
        "date": " г. р.: 1904"
    },
    {
        "name": "Виткаускас Ареяс Леоно ",
        "info": "Художник, артист"
    },
    {
        "name": "Вишняков Александр Григорьевич (Егорович)",
        "info": "художник"
    },
    {
        "name": "Владимиров Георгий Дмитриевич ",
        "info": "Художник-оформитель"
    },
    {
        "name": "Алекса́ндр Ива́нович Введе́нский",
        "date": "1904-1941",
        "info": "русский поэт, драматург, детский писатель, член Объединения реального искусства (ОБЭРИУ). 1941 года Александр Введенский был арестован по обвинению в контрреволюционной агитации. \n\nПо одной из последних версий, в связи с подходом немецких войск к Харькову был этапирован в эшелоне в Казань, но в пути 19 декабря 1941 года скончался от экссудативного плеврита. Похоронен, предположительно, на Арском или Архангельском кладбище в Казани. В 1942 году уголовное дело в отношении Введенского было прекращено за смертью обвиняемого. 30 марта 1964 года Александр Введенский был полностью реабилитирован; согласно справке о реабилитации № 6021820 от 10 апреля 1964 года, выданной Управлением КГБ при Совете Министров УССР, его дело было прекращено по п. 2 ст. 6 УПК УССР, то есть за отсутствием состава преступления."
    },
    {
        "name": "ВОВК Иван Иванович"
    },
    {
        "name": "ВОИНОВ Святослав Владимирович"
    },
    {
        "name": "ВОЛКОВ Всеволод Васильевич"
    },
    {
        "name": "Волконский Петр Николаевич",
        "info": "Художник"
    },
    {
        "name": "Вологодин (Эхна) Александр Иванович",
        "date": "1889-1939"
    },
    {
        "name": "Вологдин А.И.",
        "date": "1889-1937"
    },
    {
        "name": "Волотовский Алексей Александрович",
        "info": "Художник"
    },
    {
        "name": "Воронова Ольга Порфирьевна",
        "info": "Искусствовед"
    },
    {
        "name": "Воротников Василий Порфирьевич",
        "info": "Художник"
    },
    {
        "name": "Вощакин Алексей Васильевич",
        "info": "Живописец"
    },
    {
        "name": "Врангель Михаил Александрович",
        "info": "Архитектор, художник"
    },
    {
        "name": "Врона Иван Иванович",
        "info": "Кандидат искусствоведения"
    },
    {
        "name": "Вяземский Лев Пейсахович",
        "date": "1901",
        "info": "Художник, член Всероссийского кооперативного союза работников изобразительных искусств \"Всекохудожник\" РЕабилитирован 2 июня 1956 года"
    },
    {
        "name": "Габдрахманов рифкат Габдрахманович",
        "info": "Художник"
    },
    {
        "name": "Гаврилко Михаил",
        "date": "1882-1922"
    },
    {
        "name": "Гагенмейстер Владимир Николаевич",
        "info": "График, искусствовед"
    },
    {
        "name": "Гайдукевич Михаил Захарович",
        "info": "График, живописец"
    },
    {
        "name": "Галис Иосиф Пинхусович",
        "info": "Художник, потомственный живописец по фарфору"
    },
    {
        "name": "Гальперин Лев Соломонович",
        "date": "1886-1938",
        "info": "Художник, журналист"
    },
    {
        "name": "Гальперин Марк Иосифович",
        "info": "Скульптор"
    },
    {
        "name": "Гамбургер Леонид Эмильевич"
    },
    {
        "name": "Гейнц А.Г."
    },
    {
        "name": "Николай Афанасьевич Русаков",
        "date": "1888 - 1941",
        "info": "Расстрелян 31 декабря 1941. Челябинский художник. Ученик Фешина и Коровина. Друг Родченко и Маяковского. ... Только в 1980 году произведения Николай Русакова были наконец показаны в Челябинске. Сейчас около сотни его работ хранятся в Челябинской областной картинной галерее. Есть его работы и в Третьяковке и в частных собраниях.Во время ареста художника у семьи было изъято 90 писем, среди которых были письма Родченко, Маяковского, Бальмонта."
    },
    {
        "name": "Геккерн ",
        "info": "художник с первоначальным специальным образованием."
    },
    {
        "name": "Гельмерсен Василий Васильевич ",
        "info": "график, мастер силуэта"
    },
    {
        "name": "Геокчакян Карапет (Герас) ",
        "date": "1916-1999"
    },
    {
        "name": "Гертель Казимир Александрович",
        "info": "художник"
    },
    {
        "name": "Герценберг Вера Робертовна ",
        "info": "кандидат искусствоведени"
    },
    {
        "name": "Гершов Соломон Моисеевич",
        "info": "живописец, график"
    },
    {
        "name": "Гетман Николай Иванович",
        "date": "1917-2004",
        "info": "художником в кинотеатре в Днепропетровске. 10 лет исправительно-трудового лагеря, с поражением в правах на 5 лет,досрочно освобожден 30.08.1953"
    },
    {
        "name": "Гидони Григорий Иосифович ",
        "info": "график"
    },
    {
        "name": "Гиляров Сергей ",
        "date": "1887–1946"
    },
    {
        "name": "Гиппус Татьяна Николаевна"
    },
    {
        "name": "Гладких Алексей Борисович ",
        "date": "1907"
    },
    {
        "name": "Гликман Эрос Тимофеевич",
        "info": "студент"
    },
    {
        "name": "Глобус Вениамин Борисович"
    },
    {
        "name": "Говорова Елизавета Антоновна",
        "info": " график"
    },
    {
        "name": "Голицын Владимир Михайлович ",
        "date": "1901-1943",
        "info": "художник, моряк"
    },
    {
        "name": "Голубцов Павел Александрович ",
        "info": "живописец, реставратор, искусствовед"
    },
    {
        "name": "Гордон Вениамин Бенцианович ",
        "info": "студент"
    },
    {
        "name": "Горелов Ростислав Гаврилович ",
        "date": "1916",
        "info": "художник, живописец, график, жанрист, портретист. 2 года исправительно-трудового лагеря. Реабилитирован 1956 год"
    },
    {
        "name": "Горетой Николай Петрович ",
        "date": "1921"
    },
    {
        "name": "Горская Алла ",
        "date": "1929 - 1970"
    },
    {
        "name": "Граббе Роберт Андреевич ",
        "info": "график"
    },
    {
        "name": "Гревцев Алексей Трифонович",
        "info": "художник"
    },
    {
        "name": "Григорьев Александр Владимирович ",
        "date": "1891 - 1961",
        "info": " живописец"
    },
    {
        "name": "Григорьев Владимир Дмитриевич",
        "date": "1884"
    },
    {
        "name": "Григорьев Николай Михайлович",
        "info": "график"
    },
    {
        "name": "Грикис Яков Мартынович ",
        "info": "живописец"
    },
    {
        "name": "Гринвальд Эрнст Генрихович ",
        "info": "кинорежиссёр, художник"
    },
    {
        "name": "Грюнштам Эмиль Юльевич  ",
        "info": "художник-оформитель"
    },
    {
        "name": "Губер Эдуард Христианович"
    },
    {
        "name": "Гуммель Юрий (Гюнтер) Вильгельмович"
    },
    {
        "name": "Гуркин Григорий Иванович (Чорос-Гуркин) ",
        "date": "1870 — 1937"
    },
    {
        "name": "Гурьев Иван Петрович ",
        "date": "1875"
    },
    {
        "name": "Густав Цецилия Ивановна ",
        "info": "живописец"
    },
    {
        "name": "Густавсон Эрих Александрович ",
        "info": "художник, архитектор"
    },
    {
        "name": "Гутырь Иван Карпович ",
        "info": "театральный художник"
    },
    {
        "name": "Гуммель Гюнтер (Юрий) Вильгельмович",
        "date": "1927"
    },
    {
        "name": "Грунин Юрий Васильевич ",
        "date": "1921",
        "info": "поэт, художник. 10 лет исправительно-трудового лагеря. Был свидетелем и участником Кенгирского восстания 1954 года."
    },
    {
        "name": "Дворяшин Виктор Иванович ",
        "date": "1897",
        "info": "художник-иконописец"
    },
    {
        "name": "Деконская Ольга",
        "date": "1885–1937"
    },
    {
        "name": "Деконский Григорий Григорьевич",
        "info": "художник"
    },
    {
        "name": "Дельпес Мечислав Васильевич ",
        "info": "живописец"
    },
    {
        "name": "Дембский Иван (Ян-Донат-Карл) Фелицианович ",
        "date": "1880-1937"
    },
    {
        "name": "Дерш Пётр Станиславович ",
        "info": "художник"
    },
    {
        "name": "Дидерихс Андрей Романович"
    },
    {
        "name": "Дидык Иван Алексеевич ",
        "info": "художник"
    },
    {
        "name": "Диль Александр Яковлевич"
    },
    {
        "name": "Дистергефт Михаил Вильгельмович (Васильевич) ",
        "date": "1921-2006 ",
        "info": "график"
    },
    {
        "name": "Дмитриевский Николай Павлович  ",
        "info": "художник-график"
    },
    {
        "name": "Добродеев Георгий Владимирович ",
        "info": "живописец"
    },
    {
        "name": "Доброковский Мечислав Васильевич ",
        "info": "график, живописец, плакатист"
    },
    {
        "name": "Добромыслов Борис Петрович",
        "info": "студент"
    },
    {
        "name": "Добрянский Сергей Флавианович ",
        "info": "график"
    },
    {
        "name": "Додонов Евгений Андреевич",
        "info": "график, живописец, художник кино"
    },
    {
        "name": "Должанский Анатолий Михайлович ",
        "info": "живописец"
    },
    {
        "name": "Доморацкий Пётр Адольфович",
        "info": "художник-оформитель"
    },
    {
        "name": "Древин Рудольф-Александр Давидович  ",
        "date": "1889-1938 ",
        "info": "живописец"
    },
    {
        "name": "Друганов Борис Фёдорович  ",
        "info": "художник-оформитель"
    },
    {
        "name": "Дурново Владимир Николаевич ",
        "info": "Художник"
    },
    {
        "name": "Дурново Лидия Александровна ",
        "info": "Художник, искусствовед"
    },
    {
        "name": "Дяченко Дмитрий ",
        "date": "1887–1942"
    },
    {
        "name": "Еремин Иван Николаевич (Лазарев Дмитрий Иванович) ",
        "info": "Художник театра"
    },
    {
        "name": "Ермолаева Вера Михайловна ",
        "date": "1893-1938",
        "info": "Живописец, график, активный деятель русского авангарда, художник-иллюстратор. Приговор: 3 года исправительно-трудового лагеря. Реабилитирован: в 1989 году"
    },
    {
        "name": "Егоров Константин Федорович ",
        "date": "1897",
        "info": "Художник. Приговор:к высшей мере наказания — расстрел. Реабилитирован:5 марта 1956 года"
    },
    {
        "name": "Жидков Герман Васильевич ",
        "info": "Искусствовед, кандидат искусствоведения"
    },
    {
        "name": "Заев Сергей Константинович",
        "info": "Художник"
    },
    {
        "name": "Зайцев Адольфий Селеверстович",
        "info": "Художник"
    },
    {
        "name": "Занкишиев Ибрагим ",
        "date": "1940"
    },
    {
        "name": "Зеленков Дмитрий Владимирович"
    },
    {
        "name": "Зедделер Николай Николаевич",
        "date": "1876",
        "info": "Гравер-ксилограф, художник-декоратор на кинофабрике, сотрудник ИККИ за границей. Приговор: к высшей мере наказания — расстрел. Реабилитирован: Военной коллегией Верховного суда СССР от 11 июня 1964 года за отсутствием состава преступления"
    },
    {
        "name": "Зданевич К.М.",
        "date": "1892 -1969"
    },
    {
        "name": "Зубакин Борис Михайлович ",
        "info": "Художник"
    },
    {
        "name": "Ивасюк Николай Иванович ",
        "date": "1865–1937 ",
        "info": "Живописец"
    },
    {
        "name": "ИВАШЕВ-МУСАТОВ Сергей Михайлович",
        "date": "1900-1992",
        "info": "Живописец"
    },
    {
        "name": "Изерт Григорий Михайлович"
    },
    {
        "name": "Изнар Наталья Сергеевна",
        "info": "Художник театра"
    },
    {
        "name": "Ильин Михаил Андреевич",
        "info": "Искусствовед. Доктор искусствоведения, профессор"
    },
    {
        "name": "Ирбит Паул Янович ",
        "info": "Живописец, график"
    },
    {
        "name": "Исхаков Габбас Исхакович"
    },
    {
        "name": "Иткинд Исаак Яковлевич",
        "info": "Скульптор"
    },
    {
        "name": "Кадак Антоний Мартынович ",
        "info": "Художник"
    },
    {
        "name": "Казаков-Троянский Руслан Александрович ",
        "info": "Живописец"
    },
    {
        "name": "Казанская Мария Борисовна ",
        "info": "Живописец"
    },
    {
        "name": "Калис Эрнест",
        "info": "График"
    },
    {
        "name": "Ио́сиф Абра́мович Касси́ль ",
        "date": "1908 — 1938",
        "info": "советский журналист, писатель, педагог, литературный и театральный критик. Младший брат писателя Льва Кассиля, прототип Оси в его книге «Кондуит и Швамбрания». В мае 1937 года был исключён из ВКП(б), 4 августа того же года арестован, обвинён по 58-й статье УК РСФСР как «активный участник антисоветской террористической диверсионно-вредительской организации правых». Приговорён к 10 годам заключения, включён в «сталинский список» от 22 декабря 1937 года[2], где отнесён к 1-й категории (подлежащих расстрелу), подписи: Сталин, Молотов, Каганович, Ворошилов."
    },
    {
        "name": "Калнынь Ян Янович",
        "info": "Скульптор-монументалист"
    },
    {
        "name": "Каменецкий Олег Александрович ",
        "info": "Студент Киевского художественного института"
    },
    {
        "name": "Каменский Михаил Матвеевич ",
        "info": "Художник"
    },
    {
        "name": "Каплун Федор Федорович ",
        "info": "театральный художник"
    },
    {
        "name": "Капралов Алексей Николаевич ",
        "info": "художник"
    },
    {
        "name": "Карклинь Галина Николаевна"
    },
    {
        "name": "Каролинский Н. ",
        "info": "архитектор, художник"
    },
    {
        "name": "Карпенко Алексей Иванович ",
        "info": "скульптор"
    },
    {
        "name": "Касперович Николай ",
        "date": "1885-1938 "
    },
    {
        "name": "Кецало Зиновий Евстахиевич  ",
        "info": "график"
    },
    {
        "name": "Керсновская Евфросиния Антоновна",
        "info": "помещица, русская писательница, художница, автор мемуаров. Заключённая ГУЛАГа, высланная из Бессарабии на поселение и принудительные работы в Сибирь в 1941 году, а затем осуждённая на длительный срок исправительно-трудовых\nлагерей. Автор мемуаров , сопровождённых 700 рисунками, о своих детских годах в Одессе и Бессарабии, высылке и пребывании в ГУЛАГе.                                                                                                Полный текст мемуаров «Сколько стоит человек» Евфросинии Керсновской в шести томах был опубликован только в 2001—2002 годах."
    },
    {
        "name": "Киселис Петр Юрьевич ",
        "info": "живописец, график"
    },
    {
        "name": "Кипарис Василий Васильевич",
        "date": "1911"
    },
    {
        "name": "Кичигин Михаил Александрович",
        "date": "1883 - 1968"
    },
    {
        "name": "Клуцис Густав Густавович ",
        "date": "1895-1938 ",
        "info": "график, мастер фотомонтажа, живописец"
    },
    {
        "name": "Коваленко Григорий ",
        "date": "1868–1937 "
    },
    {
        "name": "Коварский Михаил Альбертович  ",
        "info": "художник, архитектор"
    },
    {
        "name": "Коломинов Иван Дмитриевич ",
        "info": "художник"
    },
    {
        "name": "Комаровский Владимир Алексеевич ",
        "info": "художник-иконописец"
    },
    {
        "name": "Комелова Анна Сергеевна  ",
        "info": "скульптор, искусствовед"
    },
    {
        "name": "Коннов Фёдор Данилович ",
        "info": "живописец, график"
    },
    {
        "name": "Копкин Александр Васильевич ",
        "info": "художник"
    },
    {
        "name": "Коптев Виталий Павлович ",
        "info": "художник, архитектор"
    },
    {
        "name": "Кораблев Сергей Иванович ",
        "info": "художник-плакатист"
    },
    {
        "name": "Косинский Михаил Федорович",
        "info": "искусствовед"
    },
    {
        "name": "Коскела Виктор-Альбин Викторович ",
        "info": "художник"
    },
    {
        "name": "Коцюбинская Наталья ",
        "date": "1896–1939"
    },
    {
        "name": "Коэнте Марсель-Мария Георгиевна ",
        "date": "1898-1971",
        "info": "художник-график. приговорена по ст. 58-10 ч. 1 УК РСФСР к 8 годам ИТЛ. В заключении в Каргопольском  лагере .Освобождена 19.07.1954."
    },
    {
        "name": "Краббе фон Борис Викторович"
    },
    {
        "name": "Кравцевич Михаил Самуилович"
    },
    {
        "name": "Крашановский Иван",
        "date": "1884–1939"
    },
    {
        "name": "Крейн Дмитрий Григорьевич",
        "info": "Театральный художник"
    },
    {
        "name": "Кржеминский Кость Ипполитович",
        "date": "1893–1937",
        "info": "Живописец"
    },
    {
        "name": "Кривошеина Татьяна Александровна"
    },
    {
        "name": "Кроневальд Николай Николаевич"
    },
    {
        "name": "Кропивницкий Лев Евгеньевич",
        "info": "Живописец, график, поэт"
    },
    {
        "name": "Крымшамхалов Хамзат Баксанукович ",
        "info": "Скульптор"
    },
    {
        "name": "Крушевский Георгий ",
        "date": "1863–1938"
    },
    {
        "name": "Кюршнер Альфред Оскарович"
    },
    {
        "name": "Кузнецова-Кичигина Вера Емельяновна ",
        "date": "1904 (в ряде источников 1908) — 2005",
        "info": "Живописец, график, сценограф"
    },
    {
        "name": "Кузьмин Евгений",
        "date": "1871–1942"
    },
    {
        "name": "Кукуруза Сергей Васильевич",
        "info": "График, театральный художник"
    },
    {
        "name": "Клуцис Густав Густавович ",
        "date": "1895",
        "info": "Художник-авангардист, плакатист, представитель конструктивизма, один из создателей искусства цветного фотомонтажа. Приговор: к высшей мере наказания — расстрел. Реабилитирован: 25 августа 1956 года"
    },
    {
        "name": "Куллэ Ирина Робертовна",
        "info": "График"
    },
    {
        "name": "Кульпинский Мариан Болеславович",
        "info": "Художник"
    },
    {
        "name": "Кун Глеб Сергеевич",
        "info": "График"
    },
    {
        "name": "Кундель Бронислав Станиславович",
        "info": "Художник"
    },
    {
        "name": "Курзин Михаил Иванович",
        "info": "Живописец, график"
    },
    {
        "name": "Курликов Борис Семенович",
        "info": "Живописец, оформитель"
    },
    {
        "name": "Кусургашев Георгий Дмитриевич",
        "info": "Народный художник, резчик по кости и дереву"
    },
    {
        "name": "Куткин Владимир Сергеевич",
        "date": "1926-2003",
        "info": "График"
    },
    {
        "name": "Лабренц Фёдор Фёдорович",
        "info": "Художник"
    },
    {
        "name": "Лавров Георгий Дмитриевич ",
        "info": "скульптор"
    },
    {
        "name": "Лавров Фёдор Филиппович ",
        "info": "художник"
    },
    {
        "name": "Лансере Николай ",
        "date": "1879-1942",
        "info": "Архитектор-художник, график, историк архитектуры, профессор Академии Художеств; работал в Русском музее хранителем историко-бытового отдела. высшая мера наказания — расстрел; заменен 10 годами ИТЛ. Реабилитирован: 1957 года"
    },
    {
        "name": "Лапин Пётр ",
        "date": "1887–1937"
    },
    {
        "name": "Лапицкий Савелий Яковлевич ",
        "info": "плакатист, аслуженный художник России"
    },
    {
        "name": "Ларин Юрий Николаевич ",
        "info": "живописец"
    },
    {
        "name": "Лебедев Порфирий Маркович ",
        "date": "1882 -1974"
    },
    {
        "name": "Лебединский Борис Иванович"
    },
    {
        "name": "Левина-Розенгольц Ева Павловна ",
        "date": "1898-1975",
        "info": "живописец, график. Арестована 26 августа 1949 г. Приговорена как член семьи врага народа. 10 лет ссылки Караганда, Карагандинская область, Республика Казахстан."
    },
    {
        "name": "Левов Владимир Михайлович",
        "info": "архитектор, художник"
    },
    {
        "name": "Леонов Трифон Иванович",
        "info": "художник"
    },
    {
        "name": "Лешанц Франц Иосифович",
        "info": "художник-прикладник"
    },
    {
        "name": "Лигай Борис Петрович (Ли-Зя-Сен) ",
        "info": "художник"
    },
    {
        "name": "Липковский Иван ",
        "date": "1892–1937"
    },
    {
        "name": "Лисицын Сергей Григорьевич ",
        "info": "художник-оформитель"
    },
    {
        "name": "Лихачёв Николай Петрович ",
        "info": "искусствовед, палеограф, коллекционер, музейный деятель"
    },
    {
        "name": "Лихачев Александр\nАлександрович",
        "date": "1893",
        "info": "Преподаватель Костромского текстильного рабфака, состоял в Костромском Союзе художников. высшая мера наказания — расстрел. Реабилитирован: 1964 года"
    },
    {
        "name": "Ложкин Александр Васильевич ",
        "info": "художник"
    },
    {
        "name": "Луговская Нина Сергеевна",
        "date": "1918-1993"
    },
    {
        "name": "Лузан Василий Иванович ",
        "info": "живописец"
    },
    {
        "name": "Лукашов С. ",
        "info": "график"
    },
    {
        "name": "Мавроган Александр Константинович ",
        "info": "искусствовед"
    },
    {
        "name": "Магадеев Макарим Адгамович"
    },
    {
        "name": "Магай (Мигай) Варлаам Иннокентьевич (Ма-Хег-Мен) ",
        "info": "живописец"
    },
    {
        "name": "Майер Оскар Оскарович"
    },
    {
        "name": "Макаренко Николай  ",
        "date": "1877–1938"
    },
    {
        "name": "Макаренков Алексей",
        "date": "1886–1942"
    },
    {
        "name": "Макаров Сергей Иванович ",
        "date": "1888",
        "info": "Художник-оформитель Костромского товарищества «Художник». высшая мера наказания — расстрел. Реабилитирован: 1964 года"
    },
    {
        "name": "Максимов Константин Иванович ",
        "info": "живописец"
    },
    {
        "name": "Малаев Фёдор Петрович ",
        "info": "живописец."
    },
    {
        "name": "Малаховский Бронислав Брониславович ",
        "info": "художник-график, архитектор"
    },
    {
        "name": "Малешевский Александр Сергеевич ",
        "info": "живописец"
    },
    {
        "name": "Малицкий Николай Владимирович ",
        "info": "искусствовед"
    },
    {
        "name": "Мальвинский Александр Иванович ",
        "info": "художник-оформитель"
    },
    {
        "name": "Малевич Казимир Северинович",
        "date": "1879",
        "info": "Художник-авангардист польского происхождения, педагог, теоретик искусства, философ. Основоположник супрематизма. 3 месяца тюремного заключения. Реабилитирован: Постановлением ПП ОГПУ в Ленинградском ВО дело прекращено за отсутствием состава преступления"
    },
    {
        "name": "Мальцева Нина Викторовна ",
        "date": "1912-2003?"
    },
    {
        "name": "Мамонтов Николай Андреевич"
    },
    {
        "name": "Мангольдс Херберт Августович.(HerbertsMangolds) ",
        "date": "1901-1978"
    },
    {
        "name": "Манке Евгений Константинович ",
        "info": "театральный художник"
    },
    {
        "name": "Маркус Виктор Васильевич ",
        "info": "Художник-график"
    },
    {
        "name": "Маслов Василий Николаевич",
        "info": "Живописец"
    },
    {
        "name": "Масман Вальтер Карлович",
        "info": "Художник книги"
    },
    {
        "name": "Махлис Исаак Петрович",
        "info": "Художник театра и кино"
    },
    {
        "name": "Межеевски Анджей",
        "info": "Живописец"
    },
    {
        "name": "Мейкул Карл Петрович",
        "info": "Сценограф"
    },
    {
        "name": "Мельников Василий Павлович",
        "info": "Художник-декоратор"
    },
    {
        "name": "Мереков Алексей Андреевич",
        "info": "График"
    },
    {
        "name": "Месарош Ласло",
        "info": "Скульптор. Погиб в ГУЛАГе. В 1935 с семьей эмигрировал в СССР. В 1935 с семьей эмигрировал в СССР (позже, в 1968, Марта Месарош сняла об отце документальную ленту \"Памяти Ласло Месароша\")"
    },
    {
        "name": "Миганаджиан Авагим Эммануилович"
    },
    {
        "name": "Миллер Александр Александрович",
        "info": "Археолог, этнограф, искусствовед, музейный деятель"
    },
    {
        "name": "Миллер Николай Андреевич",
        "info": "Живописец, график"
    },
    {
        "name": "Милютинский Василий Владимирович"
    },
    {
        "name": "Минаев Александр Петрович",
        "info": "Художник"
    },
    {
        "name": "Минин Ефим Семенович",
        "info": "График, живописец"
    },
    {
        "name": "Минцлова Мария Сергеевна",
        "info": "Художник-прикладник"
    },
    {
        "name": "Мирлас Давид Викторович ",
        "date": "1900-1942"
    },
    {
        "name": "Миронов Иосиф Матвеевич"
    },
    {
        "name": "Мисевич Герман Дмитриевич",
        "info": "Художник"
    },
    {
        "name": "Михайлов Василий Михайлович",
        "info": "Художник, хромолитограф"
    },
    {
        "name": "Михайлов Николай Иванович",
        "info": "Живописец"
    },
    {
        "name": "Михайлов Юхим Спиридонович",
        "info": "Живописец, искусствовед"
    },
    {
        "name": "Мкртчян Бениамин Шаваршович",
        "info": "Живописец"
    },
    {
        "name": "Молчанов Макар Гаврилович",
        "info": "Художник"
    },
    {
        "name": "Монотов Иван Романович (Сильва АрнольдиФурамэо) ",
        "info": "Скульптор"
    },
    {
        "name": "Монстерн-Дрошинский Станислав (АЛЬФОНС)"
    },
    {
        "name": "Мосягин Виктор Константинович",
        "info": "Художник"
    },
    {
        "name": "Мроз Елена Константиновна",
        "info": "Искусствовед"
    },
    {
        "name": "Мурашко Александр",
        "date": "1875–1919 "
    },
    {
        "name": "Мухатаев Алексей Антонович",
        "info": "Художник"
    },
    {
        "name": "Мухатаев Николай Антонович",
        "info": "живописец"
    },
    {
        "name": "Мухин Юрий Александрович",
        "date": "1922"
    },
    {
        "name": "Мыслина Мария Владимировна",
        "info": "график"
    },
    {
        "name": "Набатова Александра Михайловна ",
        "info": "художник-декоратор"
    },
    {
        "name": "Налепинская-Бойчук Софья Александровна ",
        "info": "живописец и график"
    },
    {
        "name": "Нарица Михаил Александрович ",
        "info": "художник, литератор"
    },
    {
        "name": "Некрасов Алексей Иванович ",
        "info": "доктор искусствоведения, профессор"
    },
    {
        "name": "Нерадовский Пётр Иванович ",
        "info": "художник, искусствовед."
    },
    {
        "name": "Неронов Кирилл Владимирович"
    },
    {
        "name": "Нестеров Михаил Васильевич  ",
        "date": "1862",
        "info": "Художник, живописец, участник Товарищества передвижных выставок и «Мира искусства»; академик живописи (1898). Находился под стражей; дело прекращено"
    },
    {
        "name": "Никитин Леонид Александрович",
        "date": "1896-1942 ",
        "info": "художник театра, живописец"
    },
    {
        "name": "Николаева Римма Николаевна ",
        "info": "художник"
    },
    {
        "name": "Никольский Виктор Александрович ",
        "info": "искусствовед, критик"
    },
    {
        "name": "Никохристо Виктор Иванович ",
        "info": "художник"
    },
    {
        "name": "Нитраи (Нитрай) Алексей Алексеевич ",
        "info": "живописец"
    },
    {
        "name": "Носкович Нина ",
        "date": "1911-1995"
    },
    {
        "name": "Обозненко Леонид ",
        "date": "1884–1937"
    },
    {
        "name": "Оводов Дмитрий Иосифович ",
        "info": "художник"
    },
    {
        "name": "Олсуфьев Юрий Александрович ",
        "info": "искусствовед, реставратор, музейный деятель"
    },
    {
        "name": "Олсуфьева Софья Владимировна ",
        "info": "художник-реставратор"
    },
    {
        "name": "Онацкий Никанор  ",
        "date": "1874–1937"
    },
    {
        "name": "Онегин Евгений Яковлевич"
    },
    {
        "name": "Орешников Михаил Михайлович ",
        "date": "1892"
    },
    {
        "name": "Орлов Михаил Илларионович",
        "info": "художник"
    },
    {
        "name": "Орт Владимир Васильевич ",
        "info": "художник книги"
    },
    {
        "name": "Осипов Петр Федорович",
        "info": "график, живописец"
    },
    {
        "name": "Ошустович Елизавета Феликсовна"
    },
    {
        "name": "Павлов Борис Павлович",
        "info": "Художник"
    },
    {
        "name": "Падалицын Николай Иванович",
        "info": "График"
    },
    {
        "name": "Падалка Иван Иванович",
        "info": "Монументалист, живописец, график. Профессор"
    },
    {
        "name": "Пазин Антон Павлович",
        "info": "Художник-оформитель"
    },
    {
        "name": "Пазухин Алексей Александрович",
        "info": "Искусствовед"
    },
    {
        "name": "Пак Тень Ю",
        "info": "Художник"
    },
    {
        "name": "Панов Игорь Владимирович ",
        "info": "Художник, переводчик"
    },
    {
        "name": "Пархоменко Иван Иванович",
        "info": "Студент"
    },
    {
        "name": "Пасько Мария Яковлена",
        "info": "Живописец"
    },
    {
        "name": "Паткуль Ольга Владимировна"
    },
    {
        "name": "Перцев Иван Андреевич",
        "info": "Художник"
    },
    {
        "name": "Петрухин Борис Афанасьевич",
        "info": "Художник"
    },
    {
        "name": "Пилипенко Борис ",
        "date": "1892–1937"
    },
    {
        "name": "Подопличко Г.И."
    },
    {
        "name": "Покровская Лидия Ивановна ",
        "info": "График, живописец"
    },
    {
        "name": "Полади-Поляк Аурэль Иванович",
        "info": "Художник-прикладник"
    },
    {
        "name": "Половцева Ксения Анатольевна",
        "info": "График"
    },
    {
        "name": "Померанцев Николай Николаевич",
        "info": "Искусствовед, реставратор, музейный деятель"
    },
    {
        "name": "Попов Борис Иванович"
    },
    {
        "name": "Порфиридов Николай Григорьевич",
        "info": "Историк, музейный деятель"
    },
    {
        "name": "Потапов Михаил Михайлович ",
        "info": "Иконописец. Египтолог"
    },
    {
        "name": "Потехин Николай Давыдович",
        "info": "Художник-декоратор"
    },
    {
        "name": "Премиров Лев Михайлович",
        "info": "Живописец, график"
    },
    {
        "name": "Претро Ипполит Александрович",
        "info": "Художник, архитектор"
    },
    {
        "name": "Протопопов Владимир Христофорович",
        "date": "г. р.: 1915"
    },
    {
        "name": "Протопопов Гай Клавдиевич",
        "info": "Художник-любитель"
    },
    {
        "name": "Пулккинен Ганнес (Ханнес) Генрихович ",
        "info": "Художник"
    },
    {
        "name": "Пунин Николай Николаевич ",
        "info": "Искусствовед"
    },
    {
        "name": "Пуренин Адольф Оттович ",
        "info": "Художник"
    },
    {
        "name": "Пустовойт Гавриил ",
        "date": "1900–1947"
    },
    {
        "name": "Пфейфер Адольф Августович"
    },
    {
        "name": "Пшесецкая (Руна) Вера Николаевна ",
        "date": "1879 – 1945/46 ",
        "info": "Художник, актриса. Сценический псевдоним Руна"
    },
    {
        "name": "Рабинович Яков Григорьевич ",
        "info": "Художник"
    },
    {
        "name": "Разумов Владимир Тимофеевич ",
        "info": "Учитель рисования"
    },
    {
        "name": "Разумовский Михаил Михайлович",
        "date": "1888"
    },
    {
        "name": "Ракитин Михаил Матвеевич",
        "date": "1915",
        "info": "Художник, скульптор. 10 лет исправительно-трудового лагеря. Реабилитирован в 1953"
    },
    {
        "name": "Раков Владимир Тихонович"
    },
    {
        "name": "Ракова (урожд. Антиох-Вербицкая) Мария Федоровна"
    },
    {
        "name": "Рассохина Любовь Александровна ",
        "info": "Художник"
    },
    {
        "name": "Рацен Николай Николаевич ",
        "info": "Художник-оформитель, журналист"
    },
    {
        "name": "Раценайс Эдуард Яковлевич",
        "info": "Художник"
    },
    {
        "name": "Рахлецкий Алексей Алексеевич",
        "date": "1893"
    },
    {
        "name": "Рейхенберг Сергей Яковлевич ",
        "info": "Сценограф"
    },
    {
        "name": "Репа Василий Васильевич  ",
        "date": "1895"
    },
    {
        "name": "Реченский Павел Иванович ",
        "date": "1924 - 1998 ",
        "info": "Живописец, график"
    },
    {
        "name": "Решетников Юрий Александрович ",
        "date": "1914-1937 ",
        "info": "Студент"
    },
    {
        "name": "Рзакулиев Алекпер"
    },
    {
        "name": "Рига Александр Сергеевич ",
        "date": "1939"
    },
    {
        "name": "Ригутини Эрнесто Сильвиович  ",
        "info": "Художник-оформитель"
    },
    {
        "name": "Риженко Яков ",
        "date": "1892–1974"
    },
    {
        "name": "Ринкус Карл Иванович ",
        "info": "Искусствовед"
    },
    {
        "name": "Рогальская Дора Моисеевна",
        "info": "Прикладник"
    },
    {
        "name": "Розвадовский Вячеслав ",
        "date": "1875–1943"
    },
    {
        "name": "Розова Людмила Константиновна ",
        "info": "Искусствовед"
    },
    {
        "name": "Россошанский Александр Васильевич ",
        "info": "Живописец"
    },
    {
        "name": "Ростопчин Фёдор Борисович",
        "info": "Музейный работник"
    },
    {
        "name": "Ротов Константин Павлович",
        "date": "1902-1959",
        "info": "Художник-график, карикатурист, иллюстратор книг. Приговор: 8 лет исправительно-трудовых лагерей. Реабилитирован: 1954 года"
    },
    {
        "name": "Рубан Александр",
        "date": "1900–1943"
    },
    {
        "name": "Рудаков Михаил Захарьевич",
        "date": "1914-1985",
        "info": "Живописец, график. Заслуженный художник РСФСР с 1982"
    },
    {
        "name": "Рудзит Артур Юрьевич",
        "date": "1893",
        "info": "Дизайнер, сценограф. Приговор: к высшей мере наказания — расстрелу с конфискацией лично ему принадлежащего имущества. Реабилитирован: 2 февраля 1957 года"
    },
    {
        "name": "Русанович Владислав Петрович",
        "info": "Художник"
    },
    {
        "name": "Рыбаков Юлий Андреевич",
        "date": "1946"
    },
    {
        "name": "Савенко Андрей Власович",
        "date": "г. р. 1903"
    },
    {
        "name": "Садков Виктор Николаевич",
        "info": "Художник"
    },
    {
        "name": "Сажин Александр Алексеевич,",
        "date": "г. р. 1912"
    },
    {
        "name": "Салонен Тойво Кустович (Густавович)",
        "info": "Театральный художник"
    },
    {
        "name": "Салтыков Александр Борисович",
        "info": "Искусствовед. Кандидат искусствоведения"
    },
    {
        "name": "Самополенко Дмитрий Владимирович",
        "info": "Художник оформитель"
    },
    {
        "name": "Саносян Саркис Манукович",
        "info": "1922-2004"
    },
    {
        "name": "Сатуров Николай Васильевич",
        "info": "Художник"
    },
    {
        "name": "Сафонова Елена Васильевна",
        "date": "1902",
        "info": "С 1928 иллюстрировала детские журналы «Чиж» и «Ёж», до ареста работала сценографом в Драматическом театре имени К.С. Станиславского и в Театре киноактера; художница. Дочь музыканта Василия Сафонова. 1 год ссылки. Реабилитирована: 1958 год"
    },
    {
        "name": "Сборомирский Болеслав Иванович",
        "info": "Живописец"
    },
    {
        "name": "Свергузин Абдрахман Садыкович",
        "date": "г. р.: 1904"
    },
    {
        "name": "Светличный Виктор Алексеевич",
        "info": "Художник книги"
    },
    {
        "name": "Свешников Борис Петрович",
        "date": "1927-1998",
        "info": "Живописец, график, иллюстратор. Приговор: 8 лет лишения свободы. Реабилитирован: 1956 год"
    },
    {
        "name": "Сговио Томас Иосифович (ThomasSgovio)  ",
        "date": "1916-1997",
        "info": "Художник. Приговор: к пяти годам принудительного труда и вечной ссылке в Красноярский край. Реабилитирован: 1956 год"
    },
    {
        "name": "Северденко Александр Петрович",
        "info": "Живописец"
    },
    {
        "name": "Седляр Василий Теофанович (Феофанович)",
        "date": "1899 -1937"
    },
    {
        "name": "Семашкевич Роман Матвеевич",
        "date": "1900",
        "info": "Художник, работал по договорам на дому. Приговор: к высшей мере наказания — расстрелу с конфискацией лично ему принадлежащего имущества. Реабилитирован: 2 июня 1958 г."
    },
    {
        "name": "Семёнов Иван Николаевич",
        "info": "Художник"
    },
    {
        "name": "Семёнов Николай Сидорович",
        "info": "Художник"
    },
    {
        "name": "Сенкевич Николай Евгеньевич",
        "info": "Художник"
    },
    {
        "name": "Сергеев Георгий Васильевич",
        "date": "1904",
        "info": "Кооперативное товарищество \"Художник\", художник. Приговор: к высшей мере наказания — расстрел. Реабилитирован: 1960 год"
    },
    {
        "name": "Серебряков Петр Эсперович",
        "date": "1898"
    },
    {
        "name": "Симбирин Александр Федорович",
        "date": "г. р. 1898"
    },
    {
        "name": "Ситнянский Евгений",
        "date": "1907–1937"
    },
    {
        "name": "Скулме Юргис Угович ",
        "date": "1928"
    },
    {
        "name": "Слипко-Москальцов Кость ",
        "date": "1901–? г. "
    },
    {
        "name": "Смирнов Алексей Петрович",
        "info": "Искусствовед, историк"
    },
    {
        "name": "Смирнов Владимир Иванович ",
        "info": "Художник книги"
    },
    {
        "name": "Смирнов Сергей Иванович ",
        "info": "Художник-реставратор"
    },
    {
        "name": "Смирнов-Русецкий Борис Алексеевич ",
        "date": "1905-1993 ",
        "info": "Живописец"
    },
    {
        "name": "Смоленцева Александра Ивановна ",
        "info": "Живописец"
    },
    {
        "name": "Соболевский Константин Станиславович  ",
        "info": "Студент"
    },
    {
        "name": "Соколов Михаил Ксенофонтович",
        "info": "Живописец, график"
    },
    {
        "name": "Соловьев Кирилл Алексеевич",
        "info": "Доктор искусствоведения, профессор"
    },
    {
        "name": "Соловьёв-Волынский Николай Александрович ",
        "info": "Художник"
    },
    {
        "name": "Соостер Юло-Ильмар Йоханнесович",
        "info": "График, живописец. Учился в Тартуском государственном художественном институте (1945–1949). Репрессирован в 1949 вместе с группой однокурсников по обвинению в антисоветской пропаганде и создании террористической группы, намеревающейся захватить самолет для побега во Францию (на самом деле обсуждалась предстоящая стажировка в Париже). Все работы, находящиеся в мастерской, было приказано сжечь. Приговорен к 10 годам лагерей. Освобожден и реабилитирован в 1956.С 1957 жил в Москве, зарабатывал на жизнь оформлением журналов и книг (в издательстве «Знание» и др.). Помимо работы над заказами издательства «Знание», Соостер в качестве художника-постановщика вместе со своим другом и соратником Юрием Соболевым-Нолевым сделал мультфильм «Стеклянная гармоника» (режиссер Андрей Хржановский, 1968) на музыку Альфреда Шнитке, написанную специально для этого проекта. Этот мультипликационный фильм был запрещен в 1969."
    },
    {
        "name": "Спасская Евгения ",
        "date": "119891–80 г. г."
    },
    {
        "name": "Старчиков Борис Александрович ",
        "info": "График"
    },
    {
        "name": "Стаховский Павел Францевич",
        "info": "Художник"
    },
    {
        "name": "Степанов Иван Иванович ",
        "info": "Живописец, график"
    },
    {
        "name": "Степанов Николай Михайлович",
        "info": "Художник"
    },
    {
        "name": "Стерлигов Владимир Васильевич  ",
        "info": "Живописец, график, теоретик искусства, поэт"
    },
    {
        "name": "Стогова Александра Георгиевна",
        "info": "Скульптор, мастер игрушки"
    },
    {
        "name": "Страутин-Цитрон Альберт Замелович",
        "info": "Искусствовед"
    },
    {
        "name": "Струхманчук Яков Михайлович",
        "info": "Художник"
    },
    {
        "name": "Страхов Владимир Васильевич"
    },
    {
        "name": "Субботин Егор Тимофеевич"
    },
    {
        "name": "Суханов Иван Петрович ",
        "date": "1881-1942  ",
        "info": "Архитектор и художник"
    },
    {
        "name": "Сухарев Семен"
    },
    {
        "name": "Сучков Федот Федотович",
        "info": "Скульптор, прозаик, поэт"
    },
    {
        "name": "Сычёв Николай Петрович",
        "date": "1883 - 1964 ",
        "info": "Искусствовед, художник. Профессор"
    },
    {
        "name": "Сысоев Николай\nАлександрович ",
        "date": "1921 г. Приговор: 7 лет исправительно-трудового лагеря. Реабилитирован: 28 июня 1990 года"
    },
    {
        "name": "Тавровский Израиль Наумович  ",
        "info": "Скульптор-анималист"
    },
    {
        "name": "Тайпале Лео Лаврович (Лаурикович) ",
        "info": "Художник"
    },
    {
        "name": "Талент Артур Карлович   ",
        "info": "Художник"
    },
    {
        "name": "Теляковский Всеволод Владимирович ",
        "info": "Театральный художник."
    },
    {
        "name": "Темплин Виктор Леонидович ",
        "date": "1920 - 1994"
    },
    {
        "name": "Тимирев Владимир Сергеевич",
        "date": "1914",
        "info": "Художник Института игрушки в г. Загорске, член Союза художников, живописец. Приговор: к высшей мере наказания — расстрел. Реабилитирован: 22 ноября 1956 года"
    },
    {
        "name": "Токай Елена Людвиговна"
    },
    {
        "name": "Тимирева Анна Васильевна",
        "date": "1893",
        "info": "Художница и поэтесса, дочь дирижёра и пианиста В. И. Сафонова. Приговор: осуждена на 2 года, освобождена в октябре 1920 года по амнистии. Реабилитирована: в марте 1960 года"
    },
    {
        "name": "Толгский Василий Петрович",
        "info": "Художник"
    },
    {
        "name": "Тоот Виктор Сигизмундович",
        "date": "1893",
        "info": "Художник, преподаватель и проректор института, главный художник-оформитель Москвы (1937-38). Приговор: 8 лет ИТЛ с поражением в правах. Реабилитирован: 7 мая 1955 года за отсутствием состава преступления"
    },
    {
        "name": "Тотьмянин Эрик Михайлович",
        "info": "Скульптор"
    },
    {
        "name": "Трегубов Геннадий Николаевич",
        "info": "Художник"
    },
    {
        "name": "Трепке Александр Павлович",
        "info": "Архитектор-художник"
    },
    {
        "name": "Трухачев Виктор Викторович",
        "info": "Театральный художник"
    },
    {
        "name": "Туркевич Иосиф (Осип) Станиславович",
        "info": "Художник"
    },
    {
        "name": "Турченко Анатолий Борисович",
        "date": "1958"
    },
    {
        "name": "Турский Антон Романович (Шапиро Маврикий Нафталович)",
        "info": "Художник"
    },
    {
        "name": "Уйттенховен Александр Владимирович",
        "date": "1897"
    },
    {
        "name": "Урманче Баки Идрисович",
        "info": "Живописец, график, скульптор"
    },
    {
        "name": "Усаитис Леонид Александрович",
        "date": "1924 - 1984"
    },
    {
        "name": "Успенский Алексей Александрович",
        "info": "Художник"
    },
    {
        "name": "Ухналёв Евгений"
    },
    {
        "name": "Ухтомский Алексей Алексеевич (Алипий)",
        "date": "1875"
    },
    {
        "name": "Ушаков-Поскочин Максим Владимирович",
        "date": "1893 - 1943"
    },
    {
        "name": "Файланд Гавриил Маркович",
        "info": "Художник"
    },
    {
        "name": "Фегединг Игорь Владимирович ",
        "info": "Художник-оформитель"
    },
    {
        "name": "Федоренко Антон Яковлевич",
        "info": "Живописец"
    },
    {
        "name": "Федоров Сергей Степанович",
        "info": "Монументалист"
    },
    {
        "name": "Федоров Федор Иванович",
        "date": "г. р. 1884"
    },
    {
        "name": "Федотов Степан Сергеевич",
        "date": "г. р. 1895"
    },
    {
        "name": "Федышин Иван Васильевич",
        "info": "Художник, искусствовед, реставратор"
    },
    {
        "name": "Филипповский Григорий Георгиевич",
        "info": "График, иллюстратор"
    },
    {
        "name": "Филянский Николай",
        "date": "1873 - 1938"
    },
    {
        "name": "Флоренский Павел Александрович",
        "info": "Религиозный философ, богослов, математик, искусствовед"
    },
    {
        "name": "Фогелер Генрих Эдуардович",
        "date": "1871-1942 ",
        "info": "Художник, архитектор, дизайнер"
    },
    {
        "name": "Фонвизин Артур Владимирович"
    },
    {
        "name": "Фроловский Михаил\nНиколаевич",
        "date": "1895",
        "info": "Поэт, художник, переводчик. в концлагерь сроком на 3 года. Реабилитирован 31 января 1994 года"
    },
    {
        "name": "Фрейберг Паула Фрицевна  ",
        "info": "График, иллюстратор"
    },
    {
        "name": "Фризен Павел Петрович"
    },
    {
        "name": "Фрицше (Ригутини-Фрицше) Елизавета Павловна ",
        "info": "Художник книги"
    },
    {
        "name": "Фриче Эдуард Петрович"
    },
    {
        "name": "Фурсей Николай Андреевич ",
        "info": "График, музыкант, театральный критик"
    },
    {
        "name": "Хамидуллин Газиз Загиддулович",
        "date": "1920"
    },
    {
        "name": "Харитонова Александра Ивановна ",
        "info": "Живописец по фарфору"
    },
    {
        "name": "Хван Владимир Алексеевич ",
        "info": "Художник"
    },
    {
        "name": "Хмурый (Бутенко) Василий ",
        "date": "1896–1940"
    },
    {
        "name": "Хайкин Зиновий\nИзраилевич",
        "date": "1913",
        "info": "Художник. 10 лет исправительно-трудового лагеря"
    },
    {
        "name": "Царнах Борис Николаевич ",
        "info": "Художник"
    },
    {
        "name": "Центомирский Стефан Петрович ",
        "info": "Живописец"
    },
    {
        "name": "Цивинский Сергей Антонович ",
        "info": "Художник"
    },
    {
        "name": "Цивирко Галина Федоровна (Гюли) ",
        "info": "График, театральный художник"
    },
    {
        "name": "Циммер Эдуард Эдуардович;"
    },
    {
        "name": "Циммерман Жанна Фердинандовна"
    },
    {
        "name": "Цирельсон Яков Исаакович ",
        "info": "Живописец"
    },
    {
        "name": "Цыбенов Нажмил Цыбенович ",
        "info": "Художник"
    },
    {
        "name": "Цымпаков Всеволод Александрович ",
        "date": "1903 – 1968"
    },
    {
        "name": "Чаусовский Марк Рафаилович ",
        "info": "Живописец"
    },
    {
        "name": "Черкасов Георгий Федорович",
        "info": "Скульптор-любитель"
    },
    {
        "name": "Черкасов Петр Алексеевич  ",
        "info": "Художник-оформитель"
    },
    {
        "name": "Черкасов Георгий\nАкимович",
        "date": "1910",
        "info": "На момент первого ареста – студент Московского химико-технологического института имени Д.И. Менделеева. 2 года тюремного заключения (Верхнеуральский политизолятор).15 марта 1989 года полностью реабилитирован"
    },
    {
        "name": "Черкасский Абрам Маркович ",
        "date": "1886-1956"
    },
    {
        "name": "Черниевский Болеслав Францевич ",
        "info": "Художник-оформитель"
    },
    {
        "name": "Чернышев Николай Александрович  ",
        "info": "Художник-реставратор"
    },
    {
        "name": "Чижевский Александр Леонидович ",
        "date": "1897-1964"
    },
    {
        "name": "Чириков Григорий Осипович ",
        "info": "Реставратор-исследователь, иконописец"
    },
    {
        "name": "Чирко-Бельский Михаил Михайлович ",
        "info": "Художник"
    },
    {
        "name": "Шабалин Василий Семенович ",
        "info": "Художник"
    },
    {
        "name": "Шалаев Константин Николаевич  ",
        "info": "Художник"
    },
    {
        "name": "Шапошников Борис Валентинович ",
        "info": "Искусствовед, музейный деятель"
    },
    {
        "name": "Швалбе Эльза Карловна  ",
        "info": "Скульптор"
    },
    {
        "name": "Шванг Иосиф Александрович ",
        "info": "Художник"
    },
    {
        "name": "Швейцер Исаак Борисович ",
        "info": "Художник"
    },
    {
        "name": "Шеварднадзе Дмитрий Ираклиевич ",
        "date": "1885",
        "info": "Живописец, художник театра и кино, исследователь древнегрузинских памятников архитектуры. Приговорен к высшей мере наказания — расстрелу с конфискацией лично ему принадлежащего имущества. освобожден 15 августа 1956 года, за отсутствием состава преступления"
    },
    {
        "name": "Шевцов Семен Яковлевич",
        "info": "Плакатист"
    },
    {
        "name": "Шевяков Григорий Романович  ",
        "info": "График"
    },
    {
        "name": "Шеплетто Юрий Иванович ",
        "info": "Живописец, монументалист"
    },
    {
        "name": "Шерман Исаак Яковлевич  ",
        "info": "График"
    },
    {
        "name": "Шерфединов Мухтар Сейдаметович",
        "date": "1908",
        "info": "Художник, архитектор. Был одним из авторов проекта парка культуры и отдыха им. М. Горького в Москве. в 1941 году добровольцем уходит на фронт. В это же самое время всю его семью депортировали в Среднюю Азию."
    },
    {
        "name": "Шиголев Сергей Иванович ",
        "info": "Живописец, художник театра"
    },
    {
        "name": "Шимоняк Лев Ильич ",
        "info": "Художник"
    },
    {
        "name": "Шкляревский Александр Ефимович",
        "date": "1897"
    },
    {
        "name": "Шлосманис Герц Абрамович ",
        "info": "Театральный художник"
    },
    {
        "name": "Шмит Федор Иванович ",
        "date": "1877–1937 ",
        "info": "Искусствовед"
    },
    {
        "name": "Штейн Вера Федоровна"
    },
    {
        "name": "Штейнберг Аркадий Акимович ",
        "date": "1907"
    },
    {
        "name": "Шубин Иван Григорьевич  ",
        "info": "Художник"
    },
    {
        "name": "Шульга Илья Максимович ",
        "date": "1878 – 1938"
    },
    {
        "name": "Шульц Михаил Михайлович"
    },
    {
        "name": "Шухаев Василий Иванович ",
        "info": "Сценограф, живописец."
    },
    {
        "name": "Шухаева Вера Федоровна ",
        "date": "1896",
        "info": "Прикладник"
    },
    {
        "name": "Щеглов Иван Никитич ",
        "info": "Живописец, график"
    },
    {
        "name": "Щеглов Сергей Владимирович",
        "info": "Живописец"
    },
    {
        "name": "Щелоков Сергей Иванович ",
        "info": "Живописец"
    },
    {
        "name": "Щёкин Сергей Алексеевич ",
        "info": "Художник"
    },
    {
        "name": "Штрикер Эва",
        "date": "1906",
        "info": "Керамист, скульптор и промышленный дизайнер; художественный руководитель фарфорово-стекольной отрасли. Выслана в Австрию (выдворение из пределов СССР)"
    },
    {
        "name": "Эйферт Владимир Александрович ",
        "date": "1884 - 1960 ",
        "info": "Живописец, искусствовед"
    },
    {
        "name": "Эппле Лев Артурович ",
        "date": "1900-1980"
    },
    {
        "name": "Эрбштейн Борис Михайлович ",
        "date": "1901",
        "info": "Выходец из состоятельной интеллигентной семьи, в последующем советский театральный художник, по образованию художник станковист. сослан в Курск, где жил вместе с еще двумя художниками, Е.В. Сафоновым и С.М. Гершовым, арестованным по тому же делу, а также какое-то время вместе с Даниилом Хармсом. Реабилитация 1958"
    },
    {
        "name": "Эрнст Фёдор ",
        "date": "1891–1942"
    },
    {
        "name": "Эссен Владимир Николаевич (Иеромонах Вениамин) ",
        "info": "Художник, священнослужитель"
    },
    {
        "name": "Эфрон Ариадна Сергеевна ",
        "date": "1912"
    },
    {
        "name": "Эфрос Абрам Маркович ",
        "info": "Искусствовед, театровед, музейный деятель, переводчик"
    },
    {
        "name": "Юзенчук Георгий Васильевич",
        "date": "1899"
    },
    {
        "name": "Юкин Павел Иванович"
    },
    {
        "name": "Юнкер-Крамская Софья Ивановна ",
        "info": "Живописец"
    },
    {
        "name": "Юркун Юрий И."
    },
    {
        "name": "Юстицкий Валентин Михайлович ",
        "date": "1892-1951 ",
        "info": "Художник"
    },
    {
        "name": "Юферов Дмитрий Владимирович"
    },
    {
        "name": "Юстицкий Валентин Михайлович ",
        "info": "Художник"
    },
    {
        "name": "Язвин Михаил Геннадиевич"
    },
    {
        "name": "Яковлев Георгий Дмитриевич ",
        "info": "Художник"
    },
    {
        "name": "Якуб Вильгельм Карлович ",
        "info": "Живописец"
    },
    {
        "name": "Якушев Павел Васильевич"
    },
    {
        "name": "Ивана Сверкунова"
    },
    {
        "name": "Александра Вологдина"
    },
    {
        "name": "Яневич Николай Владимирович  ",
        "info": "Художник"
    },
    {
        "name": "Николй Андреев"
    },
    {
        "name": "Сергея Бигоса"
    },
    {
        "name": "Григорий Гуркин"
    },
    {
        "name": "Ярыгин Александр Прокопьевич  ",
        "date": "1875"
    }
];

aboutNames.sort((a, b) => a.name.localeCompare(b.name));

aboutNames.forEach((item, index) => {
    item.id = index;
    if (!item.info) {
        item.info = "Информация отсутствует...";
    }
});

export { aboutNames };