import "./style.css"

const Footer = () => {
    return (
        <div className="footer_wrapper">
            <div className="footer">
                <ul>
                    <li>«ИМЕНА»</li>
                    <li>
                        Ежегодное чтение имен деятелей культуры пострадавших в годы советских репрессий.
                    </li>
                    <li>2021 - 2024</li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;