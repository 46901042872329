import "./style.css"
import SwiperMain from "./../Swiper/Swiper"

const Slider = () => {

    return (
        <div className="slider">
            <SwiperMain />
            <div className="slider__nav">
                <a href="https://docs.google.com/forms/d/19er_GTQvLqcn7drZZvE0kySkGc8oB4Rk3BxEEjxdc2U/viewform?edit_requested=true&fbzx=7816709930990266282" target="blank_"><div className="slider__nav-advbtn">принять участие</div></a>
            </div>

        </div>
    );
}

export default Slider;